<template>
    <div id="clinic-list">
        <div class="search-box-container">
            <div class="search-box">
                <div class="search-filter-c">
                    <span class="label">專科</span>
                    <a-dropdown :trigger="['click']">
                        <div class="filter-c-value" :data-value="categoryValue">{{categoryName || "科別"}}</div>
                        <a-menu slot="overlay">
                            <a-menu-item @click="onCategoryItemClick('', '')" key="-1">科別</a-menu-item>
                            <a-menu-divider />
                            <a-menu-item @click="onCategoryItemClick(category.codedesc, category.codeid)" :key="category.codeid" v-for="category in categorys" v-text="category.codedesc"></a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
                <div class="search-filter-a">
                    <span class="label">地區</span>
                    <div class="filter-a-values">
                        <a-dropdown :trigger="['click']">
                            <div class="filter-a-v1" :data-value="area1Value">{{area1Name || '選擇地區'}}</div>
                            <a-menu slot="overlay">
                                <a-menu-item @click="onArea1ItemClick('', '')" key="-1">選擇地區</a-menu-item>
                                <a-menu-divider />
                                <a-menu-item @click="onArea1ItemClick(area.codedesc, area.codeid)" :key="area.codeid" v-for="area in area1Datas" v-text="area.codedesc"></a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <a-dropdown :trigger="['click']">
                            <div class="filter-a-v2" :data-value="area2Value">{{area2Name || '選擇地區'}}</div>
                            <a-menu slot="overlay">
                                <a-menu-item @click="onArea2ItemClick('', '')" key="-1">選擇地區</a-menu-item>
                                <a-menu-divider />
                                <a-menu-item @click="onArea2ItemClick(area.codedesc, area.codeid)" :key="area.codeid" v-for="area in area2Datas" v-text="area.codedesc"></a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </div>
                <div class="search-filter-k">
                    <span class="label">診所</span>
                    <input class="filter-k-value" placeholder="輸入診所名稱" v-model="keyword" @keyup="onKeyEnter"/>
                </div>
                <div class="btn-search" @click="doSearch">搜索</div>
                <router-link to="/doctor/list" class="link-doctor">全部醫師</router-link>
            </div>
        </div>
        <dl class="breadcrumb">
			<dt>您當前位置:</dt>
			<dd><router-link to="/doctor/list">預約掛號</router-link><i>/</i></dd>
            <dd @click="reset"><router-link to="/hospital/list" >全部診所</router-link><i>/</i></dd>
			<dd><span>搜索</span></dd>
		</dl>
        <div class="clinic-list">
            <div class="clinic-info" v-for="clinic in dataList" :key="clinic.clinicid">
                <router-link :to="'/hospital/detail/'+clinic.clinicid">
                    <img class="icon-clinic" :src="clinic.logo" alt="">
                </router-link>
                <div class="col">
                    <div class="row">
                        <router-link :to="'/hospital/detail/'+clinic.clinicid" style="flex: 1;">
                            <h1 class="name" v-text="clinic.clinicname"></h1>
                        </router-link>
                        <a class="btn-booking" href="javascript:;" @click="onBooingBtnClick(clinic)">立即預約</a>
                    </div>
                    <p class="icon-p tel" :title="'電話：' + clinic.contacttel" v-text="`${clinic.contacttel}${clinic.contacttelother ? `（${clinic.contacttelother}）` : ''}`"></p>
                    <p v-if="clinic.workdays && '1,2,3,4,5,6,7'.indexOf(clinic.workdays) == -1" class="icon-p work-time" v-text="`工作時間: ${clinic.workdays.split(',').map(d=>weeks[d-1]).join(',')} ${clinic.workstarttime.substring(0, 5)}-${clinic.workendtime.substring(0, 5)}${clinic.worktimeremark && `(${clinic.worktimeremark})`}`"></p>
                    <p v-if="clinic.workdays && '1,2,3,4,5,6,7'.indexOf(clinic.workdays) != -1" class="work-time" v-text="`${clinic.workdays.split(',').map(d=>weeks[d-1]).shift()}至${clinic.workdays.split(',').map(d=>weeks[d-1]).pop()} ${clinic.workstarttime.substring(0, 5)}-${clinic.workendtime.substring(0, 5)}${clinic.worktimeremark && `(${clinic.worktimeremark})`}`"></p>
                    <div class="row">
                        <p class="icon-p address" :title="clinic.address" v-text="clinic.address"></p>
                        <span class="gps" @click="openMap(clinic)">地圖</span>
                    </div>
                    <p class="intro" v-text="clinic.introduce"></p>
                </div>
            </div>
            <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
            <loading v-if="loading" />
        </div>
        <booking-time :callback="onBookingTimeCallback" v-if="isShowBookingTime"/>
        <booking-doctors v-if="isBooking" :callback="onBookingCallback" :clinicid="clinicid"/>
       <a-modal
            width="1000px"
            style="top: 20px;"
            v-if="currentClinic"
            v-model="show"
            title="地圖" 
            :footer="null"
        >
            <div style="width: 1000px; height: 590px; display: flex; flex-direction: column;">
                <iframe width="100%" height="100%" frameborder="0" :src="`https://www.google.com/maps?q=${(currentClinic.address || '').replace(/樓\w+室$/, '樓').replace(/號地下$/, '號')}&loc:${currentClinic.lat}+${currentClinic.lng}&hl=zh-hk&z=16&t=p&output=embed`" @click="show=false"></iframe>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import BookingTime from '@/layouts/BookingTime.vue'
    import BookingDoctors from '@/layouts/BookingDoctors.vue'
    import clinic_list from '@/views/mixins/clinic_list'
    import SplitPage from '@/layouts/SplitPage.vue'
    import Loading from '@/layouts/Loading.vue'
	export default {
        mixins: [clinic_list],
        components: {
            BookingTime,
            BookingDoctors,
            SplitPage,
            Loading,
        },
        data(){
            return {
                categoryValue: '',
                categoryName: '',
                area1Value: '',
                area1Name: '',
                area2Value: '',
                area2Name: '',
                isBooking: false,
                isShowBookingTime: false,
                style: '',
                categorys: this.$store.getters.askCategorys || [],
                area1Datas: this.$store.getters.area1Datas || [],
                area2Datas: [],

                weeks: [
                    '星期一',
                    '星期二',
                    '星期三',
                    '星期四',
                    '星期五',
                    '星期六',
                    '星期日',
                ],

                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataList: [],
                loading: true,
                visible: false,
                keyword: '',
                categoryid: null,
                arealevel1id: null,
                arealevel2id: null,
                clinicid: null,
                currentClinic: null,
                show: false,
            }
        },
        mounted: function(){
            if(! this.area1Datas.length) {
                //获取地区
                this.$store.dispatch('getAreaList', {'langcode': 'B5'}).then((data)=>{
                    this.area1Datas = data
                })
            }
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.keyword = this.$route.query.keyword || undefined
            this.categoryid = this.$route.query.CatID || undefined
            this.arealevel1id = this.$route.query.AreaLv1 || undefined
            this.arealevel2id = this.$route.query.AreaLv2 || undefined
            this.onRefresh()
        },
        methods: {
            reset: function(){
                var path = this.$route.path
                if(path.endsWith('/hospital/list')) {
                    this.keyword = ''
                    this.pageNo = 1
                    this.visible = false
                    this.onRefresh()
                }
            },
            openMap: function(clinic) {
                // var left = (document.body.offsetWidth - 1000) / 2
                // left = left < 0 ? 0 : left
                // var options = `left=${left},top=200,toolbar=0,status=0,resizable=1,width=1000,height=536`
                // var url = `https://maps.google.com/?q=${(clinic.address || '').replace(/\d+室$/, '')}&ll=${clinic.lat},${clinic.lng}&t=f&z=15`
                // window.open(url, 'sharer', options)

                this.currentClinic = clinic
                this.show = true
            },
            onKeyEnter: function(key){
                if(key.key === 'Enter') {
                    this.doSearch()
                }
            },
            doSearch: function(){
                if(this.keyword && this.keyword.trim()) {
                    this.$router.push({path: `/hospital/list?keyword=${this.keyword}`},(e)=>{})
                } else {
                    this.$router.push({path: `/hospital/list`},(e)=>{})
                }
                this.keyword = this.keyword.trim()
                this.arealevel1id = this.area1Value
                this.arealevel2id = this.area2Value
                this.visible = false
                this.pageNo = 1
                this.onRefresh()
            },
            onRefresh: function(){
                if(this.total > 0 && this.pageNo > 0) {
                    var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                    if(maxPage > 0 && maxPage < this.pageNo) {
                        var path = this.$route.fullPath
                        var hash = this.$route.hash
                        if(hash && path.indexOf(hash) != -1) {
                            path = path.replace(hash, '')
                        }
                        this.$router.push({path: `${path}#page=1`},(e)=>{})
                        return
                    }
                }
                this.loading = true
                this.loadData({
                    "arealevel1id": this.arealevel1id || undefined,
                    "arealevel2id": this.arealevel2id || undefined,
                    "illnesscategoryid": this.categoryid || undefined,
                    "keyword": this.keyword || undefined,
                    "pageno": this.pageNo, 
                    "pagesize": this.pageSize,
                    "sortdesc": undefined,
                    "sorttypeid": undefined,
                }, (data, error)=>{
                    this.loading = false
                    if(error) {
                        this._toast.warning(this, error)
                    } else {
                        this.dataList = data.list
                        this.total = data.total
                        this.visible = this.total > 0
                        if(this.total > 0 && this.pageNo > 0) {
                            var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                            if(maxPage > 0 && maxPage < this.pageNo) {
                                var path = this.$route.fullPath
                                var hash = this.$route.hash
                                if(hash && path.indexOf(hash) != -1) {
                                    path = path.replace(hash, '')
                                }
                                this.$router.push({path: `${path}#page=1`},(e)=>{})
                                return
                            }
                        }
                    }
                })
            },
            onPageChanged: function(pageNo){
                var path = '/hospital/list'
                var query = this.$route.query
                if(query.keyword && query.CatID) {
                    this.keyword = query.keyword
                    path += '?keyword='+query.keyword+'&CatID='+query.CatID
                } else if(query.keyword) {
                    this.keyword = query.keyword
                    path += '?keyword='+query.keyword
                } else if(query.CatID) {
                    path += '?CatID='+query.CatID
                }
                this.$router.push({path: path+'#page='+pageNo},(e)=>{})
            },
            onBooingBtnClick: function(clinic){
                this.clinicid = clinic.clinicid
                this.isBooking = true
                this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
            },
            onBookingCallback: function(flag) {
                this.isBooking = false
                document.body.setAttribute('style', this.style)

                if(flag) {
                    this.isShowBookingTime = true
                }
            },
            onBookingTimeCallback: function() {
                this.isShowBookingTime = false
            },
            onArea1ItemClick: function(name, value) {
                this.area1Name = name
                this.area1Value = value
                this.area2Datas = []
                if(value) {
                    this.$store.dispatch('getAreaList', {'langcode': 'B5', 'parentId': value})
                    .then((data)=>{
                        this.area2Datas = data || []
                    })
                }
                this.area2Name = ''
                this.area2Value = ''
            },
            onArea2ItemClick: function(name, value) {
                this.area2Name = name
                this.area2Value = value
            },
            onCategoryItemClick: function(name, value) {
                this.categoryName = name
                this.categoryValue = value
            }
        },
        watch: {
            '$route.hash': function(v){
                var hash = this.$route.hash
                hash = hash.replace('#', '')
                var list = hash.split('&')
                var key = list[0].split('=')[0]
                var value = list[0].split('=')[1] / 1
                if(key == 'page') {
                    if(! isNaN(value)) {
                        this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                        this.visible = false
                    }
                }
                this.keyword = this.$route.query.keyword || undefined
                this.categoryid = this.$route.query.CatID || undefined
                this.arealevel1id = this.$route.query.AreaLv1 || undefined
                this.arealevel2id = this.$route.query.AreaLv2 || undefined
                this.onRefresh()
            },
        }
    }
</script>

<style lang="scss" scoped>
    #clinic-list {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    .clinic-list {
        min-height: 600px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding-bottom: 60px;
        position: relative;
    }

    .clinic-list > .clinic-info {
        margin: 20px 20px 0px 20px;
        border-radius: 8px;
        padding: 24px 20px 21px 20px;
        box-shadow: 1px 2px 8px 0px #E8EAF3;
        display: flex;
        flex-direction: row;
    }

    .clinic-list > .clinic-info .icon-clinic {
        width: 70px;
        height: 70px;
        margin-right: 23px;
        position: relative;
        object-fit: contain;
    }

    .clinic-list > .clinic-info .icon-clinic[src='']::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
        height: 70px;
        background-color: #fff;
        background-image: url(../../../assets/imgs/pc/img_moren@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    
    .clinic-list > .clinic-info > .col {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .clinic-list > .clinic-info > .col .name {
        color: #242020;
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
        flex: 1;
    }

    .clinic-list > .clinic-info > .col .btn-booking {
        padding: 0px 11px 0px 12px;
        background: linear-gradient(10deg,#FF8000 0%,#FFCB64 100%);
        height: 30px;
        border-radius: 15px;
        cursor: pointer;
        align-self: center;
        color: #fff;
        font-weight: bold;
        margin-left: 12px;
        line-height: 30px;
    }

    .clinic-list > .clinic-info > .col .icon-p {
        color: #242020;
        font-weight: 400;
        font-size: 16px;
        position: relative;
        padding-left: 23px;
    }

    .clinic-list > .clinic-info > .col .icon-p::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 14px;
        width: 14px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .clinic-list > .clinic-info > .col .tel {
        line-height: 12px;
        margin-top: 21px;
    }

    .clinic-list > .clinic-info > .col .tel::after {
        background-image: url(../../../assets/imgs/pc/img_iphone@2x.png);
    }

    .clinic-list > .clinic-info > .col .work-time {
        line-height: 16px;
        margin-top: 16px;
        min-height: 16px;
    }

    .clinic-list > .clinic-info > .col .work-time::after {
        background-image: url(../../../assets/imgs/pc/img_time@2x.png);
    }

    .clinic-list > .clinic-info > .col > .row {
        width: 100%;
        height: 17px;
        line-height: 17px;
        margin-top: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .clinic-list > .clinic-info > .col .address::after {
        width: 11px;
        background-image: url(../../../assets/imgs/pc/img_dib@2x.png);
    }

    .clinic-list > .clinic-info > .col .gps {
        margin-left: 18px;
        font-weight: 400;
        color: #36C4D0;
        font-size: 16px;
        line-height: 17px;
        cursor: pointer;
        width: 52px;
    }

    .clinic-list > .clinic-info > .col .intro {
        margin-top: 22px;
        color: #969696;
        line-height: 19px;
        font-size: 14px;
        font-weight: 400;
    }

    .breadcrumb {
	   display: flex;
	   flex-direction: row;
	   width: 1000px;
	   height: 42px;
	   box-sizing: border-box;
	   padding: 0px 16px;
	   background-color: #fff;
	   align-items: center;
	   font-size: 16px;
	   line-height: 15px;
	   font-weight: 400;
	   color: #969696;
	   user-select: none;
   }

	.breadcrumb dt {
		margin-right: 8px;
	}

	.breadcrumb dd {
	   color: #646464;
	   line-height: 17px;
	}

    .breadcrumb i {
        color: #B4B4B4;
    }

   .breadcrumb a {
	   margin: 0px 14px;
	   text-decoration: underline;
   }

   .breadcrumb span {
	   margin-left: 14px;
   }

    .clinic-list > .clinic-doctors {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        position: relative;
        box-sizing: border-box;
        padding: 0 20px 86px 20px;
        margin-top: 40px;
    }

    .clinic-list > .clinic-doctors > .hint {
        color: #242020;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 30px;
    }

	.split-pages-container {
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: flex-end;
	}

	.split-pages-container > .split-pages {
		display: flex;
		flex-direction: row;
	}

	.split-pages-container > .split-pages > .split-page {
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		font-weight: 400;
		margin-right: 20px;
		background-color: #DDDDDD;
		cursor: pointer;
	}

	.split-pages-container > .split-pages > .split-page.more {
		line-height: 16px;
	}

	.split-pages-container > .split-pages > .split-page:hover,
	.split-pages-container > .split-pages > .split-page.current {
		color: #fff;
		background-color: #36C4D0;
		font-weight: bold;
	}

	.split-pages-container > .btn-page-prev,
	.split-pages-container > .btn-page-next {
		height: 20px;
		width: 56px;
		text-align: center;
		line-height: 20px;
		font-weight: 300;
		color: #636363;
		background-color: #DDDDDD;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.split-pages-container > .btn-page-next {
		margin-left: 24px;
		color: #231F20;
	}

    .search-box-container {
        width: 100%;
        height: 180px;
        background-image: url(../../../assets/imgs/pc/img_dinbg2@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: row;
    }

    .search-box-container > .search-box {
        width: 1000px;
        height: 110px;
        margin: 40px auto 30px auto;
        background-image: url(../../../assets/imgs/pc/img_slbg@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: row;
    }

    .search-box-container > .search-box > .search-filter-c {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 110px;
        padding-right: 40px;
    }



    .search-box-container > .search-box > .search-filter-c::after {
        width: 1px;
        height: 60px;
        top: 27px;
        right: 0px;
        position: absolute;
        content: '';
        background-color: #fff;
    }

    .search-box-container > .search-box > .search-filter-c > .label {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: #fff;
        margin-left: 20px;
        display: inline-block;
    }

    .search-box-container > .search-box > .search-filter-c > .filter-c-value {
        width: 94px;
        height: 29px;
        margin-left: 20px;
        margin-top: 16px;
        background-color: #fff;
        line-height: 29px;
        padding-left: 12px;
        position: relative;
        cursor: pointer;
        color: #231F20;
        font-weight: bold;
        font-size: 16px;
    }
    .search-box-container > .search-box > .search-filter-c > .filter-c-value[data-value=''] {
        color: #696969;
        font-weight: 400;
    }

    .search-box-container > .search-box > .search-filter-c > .filter-c-value::before {
        right: 0;
        top: 0;
        height: 29px;
        width: 22px;
        background-color: #DCF7FB;
        content: '';
        position: absolute;
    }

    .search-box-container > .search-box > .search-filter-c > .filter-c-value::after {
        right: 6px;
        top: 10px;
        width: 10px;
        height: 7px;
        content: '';
        position: absolute;
        background-image: url(../../../assets/imgs/pc/img_xial@2x.png);
    }

    .search-box-container > .search-box > .search-filter-a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 110px;
    }

    .search-box-container > .search-box > .search-filter-a > .label {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: #fff;
        margin-left: 40px;
        display: inline-block;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values {
        display: flex;
        flex-direction: row;
        padding-right: 40px;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v1,
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2 {
        width: 110px;
        height: 29px;
        margin-left: 40px;
        margin-top: 16px;
        background-color: #fff;
        line-height: 29px;
        padding-left: 12px;
        position: relative;
        cursor: pointer;
        color: #231F20;
        font-weight: bold;
        font-size: 16px;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v1[data-value=''],
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2[data-value=''] {
        color: #696969;
        font-weight: 400;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2 {
        width: 108px;
        margin-left: 10px;
    }

    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v1::before,
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2::before {
        right: 0;
        top: 0;
        height: 29px;
        width: 22px;
        background-color: #DCF7FB;
        content: '';
        position: absolute;
    }
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v1::after,
    .search-box-container > .search-box > .search-filter-a > .filter-a-values > .filter-a-v2::after {
        right: 6px;
        top: 10px;
        width: 10px;
        height: 7px;
        content: '';
        position: absolute;
        background-image: url(../../../assets/imgs/pc/img_xial@2x.png);
    }

    .search-box-container > .search-box > .search-filter-a::after {
        width: 1px;
        height: 60px;
        top: 27px;
        right: 0px;
        position: absolute;
        content: '';
        background-color: #fff;
    }

    .search-box-container > .search-box > .search-filter-k {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 110px;
    }

    .search-box-container > .search-box > .search-filter-k  > .label {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: #fff;
        margin-left: 40px;
        display: inline-block;
    }

    .search-box-container > .search-box > .search-filter-k > .filter-k-value {
        width: 258px;
        height: 29px;
        margin-left: 40px;
        margin-top: 16px;
        background-color: #fff;
        line-height: 29px;
        padding-left: 12px;
        position: relative;
        color: #231F20;
        font-size: 16px;
        font-weight: bold;
    }

    .search-box-container > .search-box .clinic-list {
        font-weight: 400;
        color: #fff;
        font-size: 18px;
        line-height: 18px;
        margin-left: 20px;
    }

    .search-box-container > .search-box > .btn-search {
        display: inline-block;
        width: 80px;
        height: 30px;
        margin-left: 20px;  
        background-color: #DCF7FB;
        font-weight: bold;
        font-size: 18px;
        color: #36C4D0;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        margin-top: 56px;
    }

    .search-box-container > .search-box > .link-doctor {
        display: inline-block;
        height: 30px;
        font-weight: 400;
        font-size: 18px;
        color: #36C4D0;
        line-height: 30px;
        text-align: center;
        text-decoration: underline;
        margin-top: 56px;
        margin-left: 20px;
        color: #fff;
    }
</style>
