export default {
    methods: {
        loadData: function(data, callback) {
            this.$store.dispatch('getClinicList', data)
            .then((rs)=>{
                callback && callback({
                    list: rs.list || [],
                    total: rs.total || 0
                }, null)
            })
            .catch((error)=>{
                callback && callback(null, error)
            })
        }
    }
}